
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Bouncing icon animation for chat toggle button */
@keyframes bounce {
  0%, 100% {
    transform: translateY(-5%);
  }
  50% {
    transform: translateY(0);
  }
}

.chat-toggle-btn {
  animation: bounce 1s infinite;
}